import './App.css';
import exportPdf from "./apis/PdfApi";
import {useState} from "react";
import RestartBiddingDialog from "./components/RestartBiddingDialog/RestartBiddingDialog";
import {useParams} from "react-router-dom";

function App(props) {
    let {baseId, hash} = useParams()
    const [viewRestartDialog, setViewRestartDialog] = useState("default");

    const restartBidding = () => {
        setViewRestartDialog("restart");
    };

    const createDocument = () => {
        exportPdf(baseId, hash)
    }

    return viewRestartDialog === "restart" ? (
        <RestartBiddingDialog
            setView={setViewRestartDialog}
            baseId={baseId}
            hash={hash}
        />
    ) : (
    <div className="App">
      <button type="button" onClick={createDocument}>Skriv ut budförteckning</button>
      <button type="button" className="red" onClick={restartBidding}>Starta om budgivning</button>
    </div>
    );
}

export default App;
