import { jsPDF } from "jspdf";
import { format } from 'date-fns'

export default async function exportPdf(baseId, hash) {
    let {bidders, bids, broker, coa, house, object} = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v2/bidding/biddingInfoAsAdmin/${baseId}/${hash}`
    )
        .then(res => res.json())

    const getUnixTimestamp = (date) => Math.floor(new Date(date).getTime() / 1000)

    bids = bids.sort((a, b) => {
        return getUnixTimestamp(b.dtPlacedAt) - getUnixTimestamp(a.dtPlacedAt)
    })

    // Refine the list of bidders
    const includedBidders = []
    let hiddenBidderNumber = 1
    bidders.forEach((bidder, index) => {
        // Ignore bidders that have not placed a bid
        const biddersBids = bids.filter(b => b.nBidderId === bidder.nBidderId)
        if (biddersBids.length === 0) return

        // Set nBidderNumber if bidder does not have one
        // The number zero should be interpreted as a valid bidder number
        // Only null values should therefore be handled here
        if (bidder.nBidderNumber === null) {
            bidder.nBidderNumber = `D${hiddenBidderNumber}`
            hiddenBidderNumber += 1
        }

        includedBidders.push(bidder)
    })

    const startY = 30;

    const doc = new jsPDF();

    let y = startY;
    const endOfPage = 270

    const nextLine = () => {
        if (y >= endOfPage) {
            doc.addPage()
            return y = startY
        }

        return y += 6
    }

    doc.setFontSize(10);

    doc.setFillColor(76, 115, 92);
    doc.setTextColor(255, 255, 255);
    doc.rect(10, 10, 190, 6, "F");
    doc.setFont('helvetica', 'bold');
    doc.text("Budförteckning", 90, 14.5);

    // Utskriftsdatum
    doc.setTextColor(0, 0, 0);
    doc.setFont('helvetica', 'bold');
    doc.text("Utskriftsdatum", 10, y);
    doc.setFont('helvetica', 'normal');
    const date = format(new Date(), "yyyy-MM-dd")
    doc.text(date, 60, y);
    nextLine()
    nextLine()

    // Objekt
    doc.setFont('helvetica', 'bold');
    doc.text("Objekt", 10, y);
    doc.setFont('helvetica', 'normal');

    if (object.TypeID === 1) {
        // Fastighet
        doc.text(`Fastigheten med beteckning ${house.FastighetsbeteckningLM} och adressen`, 60, y);
        y += 6
        doc.text(`${object.Address}, ${object.PostalCode} ${object.PostalAddress}`, 60, y);
    }

    if (object.TypeID === 0) {
        // Fastighet
        doc.text(`Bostadsrätten till lägenhet nr ${coa.ApartmentNumber} med adressen ${object.Address}, ${object.PostalCode} ${object.PostalAddress}`, 60, y);
        y += 6
        doc.text(`i bostadsrättsföreningen ${coa.AssociationName} (org.nr ${coa.HouseAssociationOrganizationNumber})`, 60, y);
    }
    nextLine()
    nextLine()

    // Ansvarig mäklare
    doc.setFont('helvetica', 'bold');
    doc.text("Ansvarig mäklare", 10, y);
    doc.setFont('helvetica', 'normal');
    doc.text( `${broker.strBrokerName}, tel ${broker.strBrokerTelephone}, e-post ${broker.strBrokerEmail}`, 60, y);
    nextLine()
    nextLine()

    // Budlista
    doc.setFont('helvetica', 'bold');
    doc.text("Budlista", 10, y);

    doc.text("Tidpunkt", 60, y);
    doc.text("Budgivare", 100, y);
    doc.text("Bud", 130, y);
    doc.text("Villkor/anm.", 160, y);
    nextLine()

    const terms = []

    const addTerm = (term) => {
        // Existing value
        const currentIndex = terms.findIndex(ot => ot === term)
        if (currentIndex !== -1) return currentIndex + 1

        // New value
        terms.push(term)
        return terms.length
    }

    bids.forEach(bid => {
        const termsForThisBid = []
        if (bid.bPrivateByBidder || bid.bPrivateBySeller) {
            termsForThisBid.push(addTerm("Dolt bud"))
        }
        if (bid.sOtherTerms) {
            termsForThisBid.push(addTerm(bid.sOtherTerms))
        }
        if (bid.nBidStatusId === 2) {
            termsForThisBid.push(addTerm("Återkallat"))
        }

        doc.setFont('helvetica', 'normal');
        const date = format(new Date(bid.dtPlacedAt), "yyyy-MM-dd 'kl' HH:mm")
        doc.text(`${date}`, 60, y);
        const bidder = includedBidders.find(b => b.nBidderId === bid.nBidderId)

        if (bidder.nBidderNumber !== null) {
            doc.text(`${bidder.nBidderNumber}`, 100, y);
        }

        const amount = `${new Intl.NumberFormat("sv-SE").format(bid.nAmount)} kr`
        doc.text(amount, 130, y);
        doc.text(termsForThisBid.join(", "), 160, y);
        nextLine()
    })
    nextLine()

    // Budgivare
    doc.setFont('helvetica', 'bold');
    doc.text("Budgivare", 10, y);

    doc.text("Nr", 60, y);
    doc.text("Name", 70, y);
    nextLine()

    doc.setFont('helvetica', 'normal');

    includedBidders.forEach(bidder => {
        if (bidder.nBidderNumber !== null) {
            doc.text(`${bidder.nBidderNumber}`, 60, y)
        }

        doc.text(`${bidder.sFirstName} ${bidder.sLastName}`, 70, y);
        nextLine()
        const contactInfo = []
        bidder.sEmailAddress && contactInfo.push(bidder.sEmailAddress)
        bidder.sMobilePhoneNumber && contactInfo.push(bidder.sMobilePhoneNumber)
        doc.text(contactInfo.join(", "), 70, y);
        nextLine()
    })
    nextLine()

    // Villkor
    if (terms.length > 0) {
        doc.setFont('helvetica', 'bold');
        doc.text("Villkor/anm.", 10, y);
        doc.setFont('helvetica', 'normal');

        terms.forEach((term, index) => {
            doc.text(`${index + 1}`, 60, y);
            doc.text(`${term}`, 70, y);
            nextLine()
        })
    }

    doc.save(`Budförteckning för ${baseId}.pdf`);
}
