import "./RestartBiddingDialog.css";

const RestartBiddingDialog = props => {
    const cancel = () => {
        props.setView("default");
    }

    const restartBidding = async (e) => {
        e.preventDefault()
        const form = new FormData(e.currentTarget)
        const msg = form.get("confirmationText")

        if (msg === "Starta om budgivningen") {
            await fetch(
                `${process.env.REACT_APP_API_ENDPOINT}/v2/bidding/restartBiddingAsAdmin/${props.baseId}/${props.hash}`,
                {
                    method: "POST"
                }
            )
            props.setView("default")
        }
    }

    return (
        <div className="restartBidding">
            <p>Denna funktion raderar all information om budgivningen för detta objekt.</p>
            <p>Denna åtgärd kan inte ångras. Gör därför ett utdrag innan du fortsätter.</p>
            <p>Skriv in "Starta om budgivningen" i fältet nedan för att fortsätta.</p>
            <form onSubmit={restartBidding}>
                <input type="text" name="confirmationText" placeholder="Starta om budgivningen"/>
                <button className="red">Starta om budgivningen</button>
            </form>
            <button type="button" onClick={cancel}>Avbryt</button>
        </div>
    );
}

export default RestartBiddingDialog;
